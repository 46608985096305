<template>
    <div style="padding-bottom:118px;">
        <div class="open-box">
            <div class="open-head" v-loading="loading">
                <div class="content" v-html="introduce">
                </div>
            </div>
        
            <div class="content open-banner">
                <Carousel :height="420" :width="width"></Carousel>
               
            </div>
        </div>

        <div class="content open-block">
            <div class="open-block-title">数据发现与探索</div>
            <div class="open-block-list">
                <div class="block-item" @click="gotoIntroduce">
                    <img src="@static/img/icons8-physics.svg"/>
                    <div class="block-name">科技平台简介</div>
                </div>
                <div class="block-item" @click="gotoStatistics">
                    <img src="@static/img/icons8-doughnut_chart.svg"/>
                    <div class="block-name">调查统计数据</div>
                </div>
                <div class="block-item" @click="gotoReport">
                    <img src="@static/img/icons8-ratings.svg"/>
                    <div class="block-name">专题研究报告</div>
                </div>
                <div class="block-item" @click="gotoPlatformStatus">
                    <img src="@static/img/icons8-combo_chart.svg"/>
                    <div class="block-name">平台状态指标</div>
                </div>
                <div class="block-item" @click="gotoDataDownload">
                    <img src="@static/img/icons8-download.svg"/>
                    <div class="block-name">数据定制下载</div>
                </div>
                <div class="block-item" @click="gotoDataShare">
                    <img src="@static/img/icons8-cloud_link.svg"/>
                    <div class="block-name">数据共享合作</div>
                </div>
            </div>
        </div>

        <div class="content open-block">
            <div class="open-block-title">探索专题统计数据分析，了解更多科技创新管理信息
                <a href="javascript:void(0)" class="link-more">查看更多 > </a>
            </div>
            <div class="open-block-list">
                <div class="data-item" v-for="item in indexSpecialList" >
                    <div class="data-record">{{item.title}}</div>
                    <div class="data-desc">{{item.adAbstract}}</div>
                </div>
            </div>
        </div>

         <div class="content open-block" v-if="activityList.length > 0">
            <div class="open-block-title">活动资讯
            </div>
            <div class="open-block-list">
                <div class="news-item" v-for="item in activityList" @click="gotoNewsDetail(item.id)">
                    <img src="@static/img/open1.png"/>
                    <div class="news-title">{{item.title}}</div>
                    <div class="news-date">{{item.adAbstract}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue';
import Carousel from '../../components/common/Carousel';
export default {
    data(){
        return {
           
        }
    },
    components: {Carousel},
    setup(){
        const {proxy} = getCurrentInstance();
        const width = ref(1200);
        const loading = ref(false)

        const introduce = ref('')

        function getIntroduce(){
            loading.value = true;
            const url = '/hfapplication/gdsti/a/openService/topContent';
            proxy.$http.post(url).then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    introduce.value = res.data.result.content;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        const indexSpecialList = ref([])
        function getSpecialList(){
            const url = '/hfapplication/gdsti/a/openService/indexSpecial';
            proxy.$http.post(url).then(res=>{
                if(res.data.state == 0){
                    indexSpecialList.value = res.data.result;
                }
            }).catch(err=>{

            })
        }

        const activityList = ref([]);
        function getActivityList(){
            const url = '/hfapplication/gdsti/a/openService/huodongzixun';
            proxy.$http.post(url, {limit: 4, offset: 0, adTitle:''}).then(res=>{
                if(res.data.state == 0){
                    activityList.value = res.data.result.list;
                }
            }).catch(err=>{

            })
        }
        

        onMounted(()=>{
            getIntroduce();
            getSpecialList();
            getActivityList();
        })

        return {width, introduce, indexSpecialList, activityList, loading}
    },
    methods:{
        gotoIntroduce(){
            this.$router.push({namne: 'Introduce', path: '/introduce'})
        },
        gotoStatistics(){
            this.$router.push({namne: 'Statistics', path: '/statistics'})
        },
        gotoReport(){
            this.$router.push({namne: 'Report', path: '/report'})
        },
        gotoPlatformStatus(){
            this.$router.push({namne: 'PlatformStatus', path: '/platformStatus'})
        },
        gotoDataDownload(){
            this.$router.push({namne: 'DataDownload', path: '/dataDownload'})
        },
        gotoDataShare(){
            this.$router.push({namne: 'DataShare', path: '/dataShare'})
        },
        gotoBuilding(){
            this.$router.push({name: 'Building', path: '/building'})
        },
        gotoNewsDetail(id){
            this.$router.push({name: 'NewsDetail', path: '/newsDetail', query:{id: id}})
        }
    }
}
</script>

<style lang="less" scoped>
.open-block{
    margin-bottom: 26px;
    .open-block-title{
        font-family: SourceHanSansCN-Bold;
        font-size: 30px;
        color: rgba(0,0,0,0.85);
        line-height: 30px;
        margin-bottom: 27px;

        .link-more{
            float: right;
        }
    }
    .open-block-list{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        
        .block-item{
            background: #F4F5F9;
            height: 100px;
            width: 385px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 26px;
            .block-name{
                font-family: SourceHanSansCN-Medium;
                font-size: 28px;
                color: rgba(0,0,0,0.85);
                margin-left:24px;
            }
            
        }
        .block-item:hover{
            background: #D4D5D9;
        }
        .data-item{
            padding-left: 14px;
            padding-right: 14px;
            width: 254px;
            height: 130px;
            background: #FFFFFF;
            box-shadow: 0 2px 14px 0 rgba(165,184,201,0.30);
            .data-record{
                padding-top: 12px;
                padding-bottom: 8px;
                padding-right: 8px;
                font-family: SourceHanSansCN-Regular;
                font-size: 24px;
                color: #0A62CA;
                line-height: 24px;
                border-bottom: 2px solid #0A62CA;
                margin-bottom: 13px;
            }
            .data-desc{
                font-family: SourceHanSansCN-Regular;
                font-size: 16px;
                color: rgba(0,0,0,0.85);
                line-height: 16px;
            }
        }
        .news-item:hover{
            .news-title{
                color: #bfbfbf;
            }
        }
        .news-item{
            width: 284px;
            cursor: pointer;
            .news-title{
                font-family: SourceHanSansCN-Medium;
                font-size: 20px;
                color: rgba(0,0,0,0.85);
                line-height: 22px;
                margin-top:10px;
                margin-bottom: 8px;
            }
            .news-title:hover{
                color: #bfbfbf;
            }
            .news-date{
                font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.50);
                line-height: 14px;
            }
        }
    }
}
.open-head{
    height: 300px;
    width: 100%;
    background-image: url('../../../public/static/img/open_head1920.png');
    background-position: center;
    background-size: contain 100%;
    background-color: #0A62CA;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .open-title{
        font-family: SourceHanSansCN-Medium;
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 14px;
    }
    .open-desc{
        font-family: SourceHanSansCN-Regular;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 24px;
    }
}
.open-banner{
    
    width: 1200px;
}
.open-box{
    padding-bottom: 480px;
    position: relative;
    background-image: linear-gradient(180deg, #B1D1F8 0%, #FEFFFF 78%, #FFFFFF 100%);
    .open-banner{
        top: 270px;
        position: absolute;
        left: 0;
        right: 0;
        height: 495px;
    }
    .open-item{
        width: 300px;
        height: 355px;
        padding: 20px 20px;
        background: #FFFFFF;
        box-shadow: 0 2px 20px 0 rgba(165,184,201,0.30);
        .item-name{
            font-family: SourceHanSansCN-Medium;
            font-size: 22px;
            color: #0A62CA;
            line-height: 22px;
            padding-bottom: 11px;
            border-bottom: 2px solid #0A62CA;
            text-align: center;
        }
        .item-list{
            padding-top: 15px;
            padding-left: 20px;
            padding-right: 20px;
            text-align: left;
            .item-text{
                font-family: SourceHanSansCN-Regular;
                font-size: 18px;
                color: rgba(0,0,0,0.85);
                margin-bottom: 16px;
                line-height: 18px;
            }
        }
    }
}
</style>

